import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box } from '@sevenrooms/core/ui-kit/layout'
import { adminVenuePackageProvisioningMessages } from '../../locales'

export function VenuePackageWizardSection({ venueId }: { venueId: string }) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  return (
    <Box p="lm">
      <BaseSection
        title={formatMessage(adminVenuePackageProvisioningMessages.wizardTitleVenue)}
        description={formatMessage(adminVenuePackageProvisioningMessages.wizardDetails)}
        actions={
          <Button
            href={nav.href(routes.api.packageWizardVenueDownload, { params: { venueId } })}
            isExternal
            data-test="wizard-download-button"
          >
            {formatMessage(adminVenuePackageProvisioningMessages.wizardDownloadButton)}
          </Button>
        }
      >
        &nbsp;
      </BaseSection>
    </Box>
  )
}
