import React, { useState, useEffect } from 'react'
import {
  useGetIntegrationTypesQuery,
  useGetEntityInfoDataQuery,
  useDeleteClientAppMutation,
  useAddClientAppMutation,
  type ApiIntegrationType,
} from '@sevenrooms/core/api'
import { useForm, useWatchMany, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Link, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Input } from '@sevenrooms/core/ui-kit/core'
import { FormInput, FormSelect, Label, SwitchSet, Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Loader,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  VStack,
  Window,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@sevenrooms/core/ui-kit/layout'
import { Par, Text, Header, ErrorText } from '@sevenrooms/core/ui-kit/typography'
import { copyToClipboard } from '@sevenrooms/core/utils'
import { apiAudienceMessages } from '../../locales'

export const entityTypesMap: { [index: string]: string[] } = {
  venue: ['Venue', 'Venue Group'],
  venue_group: ['Venue Group', 'Venue Group'],
  concierge: ['Concierge', 'Concierge Group'],
  concierge_group: ['Concierge Group', 'Concierge Group'],
}

const parentTypesMap: { [index: string]: string } = {
  venue: 'venue_group',
  concierge: 'concierge_group',
}

const addAudienceForm = z.object({
  name: z.string(),
  integrationOwner: z.string().nullable().default(null),
  isGroupLevel: z.string(),
  entityType: z.string(),
  entityId: z.string(),
  integrationType: z.string(),
  integrationTypeId: z.string().nullable().default(null),
})

export const integrationTypesConstOptions = [
  { id: '2ND_PARTY', label: 'SECOND PARTY' },
  { id: '3RD_PARTY', label: 'THIRD PARTY' },
  { id: 'POS', label: 'POS' },
  { id: 'CUSTOM_AUDIENCE', label: 'CUSTOM AUDIENCE' },
]

function getSwitchChoice(value: string, label: string) {
  return { value, label }
}

export function ApiAudienceManager() {
  const nav = useNavigation()
  const params = nav.matchParams(routes.admin.clientappsManager)
  const { entityId, entityType } = params as { entityId: string; entityType: string }

  const [showAddAudienceModal, setShowAddAudienceModal] = useState(false)
  const [showDeleteAudienceModal, setShowDeleteAudienceModal] = useState(false)
  const [deleteAudienceId, setDeleteAudienceId] = useState('')
  const [deleteClientApp, { isLoading: isDeleting }] = useDeleteClientAppMutation()
  const [addClientApp, { isLoading: isAddingClientApp }] = useAddClientAppMutation()

  const isGroupLevel = ['venue_group', 'concierge_group'].indexOf(entityType) > -1
  const entityParentType = isGroupLevel ? entityType : parentTypesMap[entityType]

  const form = useForm(addAudienceForm, {
    defaultValues: {
      isGroupLevel: isGroupLevel ? 'true' : 'false',
      entityType,
      entityId,
    },
  })
  const { field, handleSubmit, reset, formState } = form

  const [newName, isNewGroupLevel, newEntityType, newEntityId, newIntegrationType, newIntegrationTypeId, integrationOwner] = useWatchMany(
    field,
    ['name', 'isGroupLevel', 'entityType', 'entityId', 'integrationType', 'integrationTypeId', 'integrationOwner']
  )

  const getIntegrationTypesQuery = useGetIntegrationTypesQuery()

  const { isError: integrationTypesIsError, isLoading: integrationTypesIsLoading } = getIntegrationTypesQuery
  let { data: integrationTypesData } = getIntegrationTypesQuery

  const onSubmit = async () => {
    await addClientApp({
      entityType: newEntityType,
      entityId: newEntityId,
      name: newName,
      integrationType: newIntegrationType || '',
      integrationTypeId: newIntegrationTypeId || '',
      integrationOwner: integrationOwner || '',
    })
    setShowAddAudienceModal(false)
  }
  useEffect(() => {
    if (formState?.isSubmitSuccessful) {
      reset()
    }
  }, [formState, reset])

  const handleFocus = (event: React.MouseEvent<HTMLInputElement>) => {
    if (event.target instanceof HTMLInputElement) {
      event.target.select()
    }
  }
  const getDataQuery = useGetEntityInfoDataQuery({
    entityType,
    entityId,
  })

  const { isError } = getDataQuery
  const { data } = getDataQuery
  const { formatMessage } = useLocales()

  if (!integrationTypesData && !integrationTypesIsLoading && !integrationTypesIsError) {
    integrationTypesData = []
  }

  const integrationTypesOptions = integrationTypesData
    ? integrationTypesData
        .filter((item: ApiIntegrationType) => item.type === newIntegrationType)
        .map((item: ApiIntegrationType) => ({
          label: item.name,
          id: item.id,
        }))
    : []

  if (!data || isError || integrationTypesIsError || !integrationTypesData) {
    return <Loader />
  }

  const [entityName, entityGroupName] = entityTypesMap[entityType] || []
  const newAudienceLevelChoices = isGroupLevel
    ? [getSwitchChoice('true', formatMessage(apiAudienceMessages.levelGroup))]
    : [
        getSwitchChoice('false', formatMessage(apiAudienceMessages.levelEntity, { entityName })),
        getSwitchChoice('true', formatMessage(apiAudienceMessages.levelGroup)),
      ]

  const isPosOr3rdParty = ['3RD_PARTY', 'POS'].includes(newIntegrationType)
  const is2ndParty = ['2ND_PARTY'].includes(newIntegrationType)

  return (
    <>
      <Header>{formatMessage(apiAudienceMessages.header, { entityObjectName: data.entityName })}</Header>
      <Window active={showAddAudienceModal} setIsActive={setShowAddAudienceModal}>
        <Modal ariaLabel="Base">
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader
              onClose={() => {
                setShowAddAudienceModal(false)
              }}
            >
              <VStack spacing="s">
                <ModalTitle title={formatMessage(apiAudienceMessages.modalHeader)} />
              </VStack>
            </ModalHeader>
            <ModalBody>
              <SwitchSet
                name="isGroupLevel"
                choices={newAudienceLevelChoices}
                selected={isNewGroupLevel}
                onChange={({ value }) => {
                  field.prop('isGroupLevel').set(value)

                  if (value === 'true') {
                    field.prop('entityType').set(entityParentType || '')
                    field.prop('entityId').set(data?.entityGroupId || '')
                  } else {
                    field.prop('entityType').set(entityType)
                    field.prop('entityId').set(entityId)
                  }
                }}
              />

              <Label width="100%" primary={formatMessage(apiAudienceMessages.addTypeLabel)}>
                <FormSelect
                  field={field.prop('integrationType')}
                  placeholder="Please, select value"
                  options={integrationTypesConstOptions}
                  searchable={false}
                />
              </Label>

              <Label width="100%" primary={formatMessage(apiAudienceMessages.addNameLabel)}>
                <FormInput required field={field.prop('name')} />
              </Label>
              {is2ndParty && (
                <Label width="100%" primary={formatMessage(apiAudienceMessages.integrationOwner)}>
                  <FormInput required field={field.prop('integrationOwner')} />
                </Label>
              )}
              {isPosOr3rdParty && (
                <Label width="100%" primary={formatMessage(apiAudienceMessages.addTypeIdLabel)}>
                  <FormSelect
                    field={field.prop('integrationTypeId')}
                    placeholder="Please, select value"
                    options={integrationTypesOptions}
                    searchable
                    withEmpty
                    hideEmptyOption
                  />

                  <Link target="_blank" to="/admin/api-integration-types">
                    Manage types
                  </Link>
                </Label>
              )}
            </ModalBody>
            <ModalFooter>
              <ModalActions>
                <Button
                  data-test="dismiss-audience-modal-button"
                  variant="secondary"
                  onClick={() => {
                    setShowAddAudienceModal(false)
                  }}
                >
                  {formatMessage(apiAudienceMessages.dismiss)}
                </Button>
                <Button data-test="add-audience-modal-button" disabled={isAddingClientApp} variant="primary" type="submit">
                  {formatMessage(apiAudienceMessages.add)}
                </Button>
              </ModalActions>
            </ModalFooter>
          </form>
        </Modal>
      </Window>
      <Window active={showDeleteAudienceModal} setIsActive={setShowDeleteAudienceModal}>
        <Modal ariaLabel="Base">
          <ModalHeader
            onClose={() => {
              setShowDeleteAudienceModal(false)
            }}
          >
            <VStack spacing="s">
              <ModalTitle
                title={formatMessage(apiAudienceMessages.deleteWarningHeader)}
                subTitle={formatMessage(apiAudienceMessages.deleteWarningHeaderSubTitle)}
              />
            </VStack>
          </ModalHeader>
          <ModalBody>
            <Text>{formatMessage(apiAudienceMessages.deleteWarning)}</Text>
          </ModalBody>
          <ModalFooter>
            <ModalActions>
              <Button
                data-test="show-audience-modal-button"
                onClick={() => {
                  setShowDeleteAudienceModal(false)
                }}
              >
                {formatMessage(apiAudienceMessages.dismiss)}
              </Button>
              <Button
                data-test="delete-audience-modal-button"
                onClick={async () => {
                  await deleteClientApp({ clientAppId: deleteAudienceId })
                  setShowDeleteAudienceModal(false)
                }}
                variant="primary-warning"
              >
                {formatMessage(apiAudienceMessages.delete)}
              </Button>
            </ModalActions>
          </ModalFooter>
        </Modal>
      </Window>
      {!data.apiAddOnEnabled && (
        <Par>
          <ErrorText>{formatMessage(apiAudienceMessages.noApiAddOn)}</ErrorText>
        </Par>
      )}
      {isAddingClientApp ? (
        <Loader />
      ) : (
        <Button
          data-test="add-client-api-button"
          onClick={() => {
            setShowAddAudienceModal(true)
          }}
        >
          {formatMessage(apiAudienceMessages.addNewButton)}
        </Button>
      )}
      <Label primary={formatMessage(apiAudienceMessages.baseURL)}>
        <Input defaultValue={data.baseUrl} readOnly fullWidth onClick={e => handleFocus(e)} />
      </Label>
      {!isGroupLevel && (
        <Label primary={`${entityName} ID`}>
          <Input defaultValue={data.entityId || ''} readOnly fullWidth onClick={e => handleFocus(e)} />
        </Label>
      )}
      <Label primary={isGroupLevel ? entityName : `${entityName} Group ID`}>
        <Input defaultValue={data.entityGroupId || ''} readOnly fullWidth onClick={e => handleFocus(e)} />
      </Label>
      <div style={{ paddingTop: 40, paddingBottom: 40 }}>
        <Header>{entityName} Client APIs/Audiences</Header>
        <Table>
          <thead>
            <tr>
              <TableCell isHeader>{formatMessage(apiAudienceMessages.tableHeaderName)}</TableCell>
              <TableCell isHeader>{formatMessage(apiAudienceMessages.tableHeaderIntegrationType)}</TableCell>
              <TableCell isHeader>{formatMessage(apiAudienceMessages.tableHeaderLevel)}</TableCell>
              <TableCell isHeader>{formatMessage(apiAudienceMessages.tableHeaderClientId)}</TableCell>
              <TableCell isHeader>{formatMessage(apiAudienceMessages.tableHeaderClientSecret)}</TableCell>
              <TableCell isHeader>{formatMessage(apiAudienceMessages.tableHeaderActive)}</TableCell>
              <TableCell isHeader>{formatMessage(apiAudienceMessages.tableHeaderWebhook)}</TableCell>
              <TableCell isHeader />
            </tr>
          </thead>

          <TableBody>
            {data.clientApps.map(app => (
              <TableRow key={app.clientId}>
                <TableCell>{app.name}</TableCell>
                <TableCell>
                  {app.apiIntegrationType}
                  <Par fontSize="xs">{app.integrationOwner}</Par>
                </TableCell>
                <TableCell>{app.isEntityGroupLevel ? entityGroupName : entityName}</TableCell>
                <TableCell>
                  <pre style={{ fontSize: '10px', maxWidth: '200px', lineBreak: 'anywhere', whiteSpace: 'pre-wrap' }}>{app.clientId}</pre>
                  <Button data-test="copy-audience-button" variant="secondary" size="xs" onClick={() => copyToClipboard(app.clientId)}>
                    {formatMessage(apiAudienceMessages.copy)}
                  </Button>
                </TableCell>
                <TableCell>
                  <Button
                    data-test="copy-clipboard-audience-button"
                    variant="secondary"
                    size="xs"
                    onClick={() => copyToClipboard(app.clientSecret)}
                  >
                    {formatMessage(apiAudienceMessages.copy)}
                  </Button>
                </TableCell>
                <TableCell>
                  {app.isActive ? (
                    <Icon color="primaryIcons" size="2x" name="VMSWeb-check-circle-outline" />
                  ) : (
                    <Icon color="primaryIcons" size="2x" name="VMSWeb-close-circle-outline" />
                  )}
                </TableCell>
                <TableCell>
                  {app.isWebhookConfigured ? (
                    <Icon color="primaryIcons" size="2x" name="VMSWeb-check-circle-outline" />
                  ) : (
                    <Icon color="primaryIcons" size="2x" name="VMSWeb-close-circle-outline" />
                  )}
                </TableCell>
                <TableCell>
                  {isDeleting && app.clientId === deleteAudienceId ? (
                    <Loader />
                  ) : (
                    <VStack height="60px" justifyContent="space-between">
                      <Button
                        data-test="edit-audience-button"
                        size="xs"
                        variant="primary"
                        target="_blank"
                        href={`/admin/clientapps/${app.clientId}/edit`}
                      >
                        {formatMessage(apiAudienceMessages.edit)}
                      </Button>
                      <Button
                        data-test="delete-audience-button"
                        size="xs"
                        variant="primary-warning"
                        onClick={() => {
                          setDeleteAudienceId(app.clientId)
                          setShowDeleteAudienceModal(true)
                        }}
                      >
                        {formatMessage(apiAudienceMessages.delete)}
                      </Button>
                    </VStack>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
