import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/utils'
import { VenueHistoryTracking } from './components'

function renderVenueHistoryTracking(id: string, venueId: string, venueName: string) {
  return render(
    <AdminPage>
      <VenueHistoryTracking venueId={venueId} venueName={venueName} />
    </AdminPage>,
    document.getElementById(id)
  )
}

svrExport('HistoryTracking', 'render', renderVenueHistoryTracking)
