import _ from 'lodash'
import { type FormEvent, useCallback, useMemo } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin'
import { useGetAdminOneTimeEmailCampaignTemplatesQuery, type OneTimeEmailCampaignTemplate } from '@sevenrooms/core/api'
import { OneTimeEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { useForm, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useDestination, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { SelectFilter, SelectTarget } from '@sevenrooms/core/ui-kit/core'
import { Button, FormMultiSelect } from '@sevenrooms/core/ui-kit/form'
import { BorderedBox, Box, Flex, HStack, Loader, Tab, TabList, TabPanel, Tabs } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { useOneTimeEmailCampaignTemplateCategories } from '@sevenrooms/marketing'
import { AdminOneTimeEmailCampaignTemplatesTabs } from '../../const'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'
import { AdminOneTimeEmailCampaignTemplateCard } from '../AdminOneTimeEmailCampaignTemplateCard'

export function AdminOneTimeEmailCampaignTemplates() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const filtersForm = useForm(filtersSchema, {
    defaultValues: {
      selectedCategories: [],
    },
  })
  const { field: filters, watch, setValue } = filtersForm
  const { data, isFetching } = useGetAdminOneTimeEmailCampaignTemplatesQuery({})
  const [search, selectedCategories] = watch(['search', 'selectedCategories'])
  const emailTemplates = useMemo(() => data?.results || [], [data])
  const categories = useOneTimeEmailCampaignTemplateCategories()

  const isTabHidden = (status: AdminOneTimeEmailCampaignTemplatesTabs) =>
    !emailTemplates.find(emailTemplate => (emailTemplate?.status as unknown as AdminOneTimeEmailCampaignTemplatesTabs) === status)

  const tabs = Object.values(AdminOneTimeEmailCampaignTemplatesTabs)
  const { query } = useDestination(routes.admin.oneTimeEmailCampaignsTemplates)
  const activeTab = tabs.find(tab => tab.toLowerCase() === query?.tab?.toLowerCase())
  const isActiveTabHidden = activeTab ? isTabHidden(activeTab) : true
  const activeTabIndex: number = isActiveTabHidden ? 0 : tabs.findIndex(tab => tab.toLowerCase() === query?.tab?.toLowerCase())

  const filteredEmailTemplates = useMemo(
    () =>
      emailTemplates
        .filter(emailTemplate =>
          !_.isNil(search)
            ? _.includes(
                emailTemplate.unpublishedChanges?.campaignName?.toLowerCase() || emailTemplate.campaignName.toLowerCase(),
                search.toLowerCase()
              )
            : true
        )
        .filter(emailTemplate =>
          !_.isEmpty(selectedCategories)
            ? selectedCategories.find(selectedCategory => _.includes(emailTemplate.templateCategories, selectedCategory))
            : true
        ),
    [emailTemplates, search, selectedCategories]
  )

  const renderEmailTemplates = useCallback(
    (status: OneTimeEmailCampaignStatusEnum) => {
      const result = _.orderBy(filteredEmailTemplates, emailTemplate => emailTemplate?.updated?.toJsDate(), 'desc').filter(
        emailTemplate => emailTemplate.status === status
      )

      if (!result.length) {
        return (
          <BorderedBox>
            <Flex justifyContent="center" alignItems="center" height="100%" width="100%">
              <SecondaryText>{formatMessage(adminOneTimeEmailCampaignTemplatesMessages.filtersEmptyState)}</SecondaryText>
            </Flex>
          </BorderedBox>
        )
      }

      return (
        <Flex flexWrap="wrap" rowGap="lm" columnGap="lm">
          {result.map(emailTemplate => (
            <Flex key={emailTemplate?.id} flexBasis="470px" flexGrow={0} flexShrink={0} height="315px">
              <AdminOneTimeEmailCampaignTemplateCard emailTemplate={emailTemplate as OneTimeEmailCampaignTemplate} />
            </Flex>
          ))}
        </Flex>
      )
    },
    [filteredEmailTemplates, formatMessage]
  )

  const getEmailCampaignsTemplatesTabCount = (status: OneTimeEmailCampaignStatusEnum): number =>
    filteredEmailTemplates.filter(emailTemplate => emailTemplate.status?.toLowerCase() === status?.toLowerCase())?.length

  if (isFetching) {
    return (
      <Box p="lm">
        <Loader />
      </Box>
    )
  }

  return (
    <>
      <AdminPageMeta title={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.title)} />

      <AdminPageContent
        title={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.title)}
        actions={
          <Button
            variant="primary"
            icon="VMSWeb-add"
            href={nav.href(routes.admin.oneTimeEmailCampaignsTemplates.create)}
            data-test="create-new-template-button"
          >
            {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.createNewTemplate)}
          </Button>
        }
      >
        <Box pt="m" pb="lm">
          <HStack width="100%" spacing="s" justifyContent="space-between" pb="m">
            <Box width="215px">
              <FormMultiSelect
                data-test="category-filter"
                options={categories}
                useCheckboxItems
                usePills={false}
                displayAsTextWhenCollapsed
                field={filters.prop('selectedCategories')}
                scrollLock
                showMoreLabel=""
                placeholder={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.categoryFilterPlaceholder)}
              />
            </Box>

            <Box width="330px">
              <SelectTarget>
                <SelectFilter
                  data-test="search-filter"
                  placeholder={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.searchPlaceholder)}
                  selectedValue={search || undefined}
                  disabled={isFetching}
                  searchable
                  onChange={(event: FormEvent<HTMLInputElement>) => {
                    setValue('search', event.currentTarget.value)
                  }}
                />
              </SelectTarget>
            </Box>
          </HStack>

          <Tabs
            onSelect={index => {
              const nextTab = tabs[index]

              nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
                queryMode: 'replace',
                query: {
                  tab: nextTab ?? AdminOneTimeEmailCampaignTemplatesTabs.ACTIVE,
                },
              })
            }}
            selectedIndex={activeTabIndex}
          >
            <TabList>
              {!isTabHidden(AdminOneTimeEmailCampaignTemplatesTabs.DRAFT) && (
                <Tab data-test={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.inactiveDraftTemplates)}>
                  {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.inactiveDraftTemplates)}&nbsp;(
                  {getEmailCampaignsTemplatesTabCount(OneTimeEmailCampaignStatusEnum.DRAFT)})
                </Tab>
              )}
              {!isTabHidden(AdminOneTimeEmailCampaignTemplatesTabs.ACTIVE) && (
                <Tab data-test={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.VMSLiveTemplates)}>
                  {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.VMSLiveTemplates)}&nbsp;(
                  {getEmailCampaignsTemplatesTabCount(OneTimeEmailCampaignStatusEnum.ACTIVE)})
                </Tab>
              )}
              {!isTabHidden(AdminOneTimeEmailCampaignTemplatesTabs.INACTIVE) && (
                <Tab data-test={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.archivedTemplates)}>
                  {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.archivedTemplates)}&nbsp;(
                  {getEmailCampaignsTemplatesTabCount(OneTimeEmailCampaignStatusEnum.INACTIVE)})
                </Tab>
              )}
            </TabList>
            <Box pt="m">
              {!isTabHidden(AdminOneTimeEmailCampaignTemplatesTabs.DRAFT) && (
                <TabPanel>{renderEmailTemplates(OneTimeEmailCampaignStatusEnum.DRAFT)}</TabPanel>
              )}
              {!isTabHidden(AdminOneTimeEmailCampaignTemplatesTabs.ACTIVE) && (
                <TabPanel>{renderEmailTemplates(OneTimeEmailCampaignStatusEnum.ACTIVE)}</TabPanel>
              )}
              {!isTabHidden(AdminOneTimeEmailCampaignTemplatesTabs.INACTIVE) && (
                <TabPanel>{renderEmailTemplates(OneTimeEmailCampaignStatusEnum.INACTIVE)}</TabPanel>
              )}
            </Box>
          </Tabs>
        </Box>
      </AdminPageContent>
    </>
  )
}

const filtersSchema = z.object({
  search: z.string().nullable(),
  selectedCategories: z.array(z.string()),
})
