import { useCallback } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import {
  useGetVenuePackageProvisioningDiffQuery,
  useUpdatePackageProvisioningMutation,
  type ProvisioningDiff,
  type VenuePackageProvisioningDiffResponse,
} from '@sevenrooms/core/api'
import { useFormContext, type UseForm, type z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, Label } from '@sevenrooms/core/ui-kit/form'
import { HStack, Loader, notify, Box, Breadcrumbs, DividerLine, BaseSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link, SecondaryText, StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import { adminVenuePackageProvisioningMessages } from '../../locales'
import type { PackageProvisioningForm } from './PackageProvisioning.zod'

interface ProductProvisioningFormProps {
  venueId: string
  venueName: string
  currentPackage: string
  newPackage: string
  selectedBetas: string[]
  selectedAddons: SelectedAddonsType
}

interface SelectedAddonsType {
  api: boolean
  onlineOrdering: boolean
  emailCampaigns: boolean
  pms: boolean
  smsMarketing: boolean
  premiumIntegrations: string[]
}

interface ReviewSectionProps {
  categoryName: string
  diffs: ProvisioningDiff[]
}

interface ReviewRowProps {
  diff: ProvisioningDiff
}

interface BooleanDiffProps {
  value: boolean
}

interface ListDiffProps {
  values: string[]
}

interface ReviewSummaryProps {
  venueName: string
  currentPackage: string
  newPackage: string
  diffData: VenuePackageProvisioningDiffResponse
}

export function ReviewSummary({ venueName, currentPackage, newPackage, diffData }: ReviewSummaryProps) {
  const { formatMessage } = useLocales()

  const featureItems = diffData.filter(
    item =>
      item.categoryName === 'Reservation & Waitlist Management' ||
      item.categoryName === 'Marketing' ||
      item.categoryName === 'Venue Management'
  )

  const featureChanges = featureItems.reduce((acc, item) => acc + item.diffs.length, 0)

  const addonItems = diffData.filter(item => item.categoryName === 'Addons')
  const addonChanges = addonItems.reduce((acc, item) => acc + item.diffs.length, 0)

  const betaItems = diffData.filter(item => item.categoryName === 'Betas')
  const betaChanges = betaItems.reduce((acc, item) => acc + item.diffs.length, 0)
  return (
    <BaseSection>
      <Box pb="lm">
        <DividerLine margin="none" />
      </Box>
      <HStack justifyContent="space-around" alignItems="center" pt="s" pb="m">
        <VStack spacing="s" alignItems="center">
          <Text textStyle="h2">{venueName}</Text>
          <SecondaryText>{formatMessage(adminVenuePackageProvisioningMessages.venueLabel)}</SecondaryText>
        </VStack>
        <VStack spacing="s" alignItems="center">
          <Text textStyle="h2">{featureChanges}</Text>
          <SecondaryText>{formatMessage(adminVenuePackageProvisioningMessages.featureChangeCountLabel)}</SecondaryText>
        </VStack>
        <VStack spacing="s" alignItems="center">
          <Text textStyle="h2">{addonChanges}</Text>
          <SecondaryText>{formatMessage(adminVenuePackageProvisioningMessages.addonChangeCountLabel)}</SecondaryText>
        </VStack>
        <VStack spacing="s" alignItems="center">
          <Text textStyle="h2">{betaChanges}</Text>
          <SecondaryText>{formatMessage(adminVenuePackageProvisioningMessages.betaChangeCountLabel)}</SecondaryText>
        </VStack>
        <VStack spacing="s" alignItems="center">
          <HStack spacing="s" alignItems="center">
            <StatusLabel variant="error">{currentPackage}</StatusLabel>
            <Text textStyle="h2">→</Text>
            <StatusLabel variant="success">{newPackage}</StatusLabel>
          </HStack>
          <SecondaryText>{formatMessage(adminVenuePackageProvisioningMessages.packageChangeLabel)}</SecondaryText>
        </VStack>
      </HStack>
    </BaseSection>
  )
}

function ListDiff({ values }: ListDiffProps) {
  return values ? (
    <>
      {values.map(value => (
        <StatusLabel key={value} variant="success">
          {value}
        </StatusLabel>
      ))}
    </>
  ) : null
}

function BooleanDiff({ value }: BooleanDiffProps) {
  const { formatMessage } = useLocales()

  return (
    <>
      {value ? (
        <StatusLabel variant="success">{formatMessage(adminVenuePackageProvisioningMessages.enabled)}</StatusLabel>
      ) : (
        <StatusLabel variant="error">{formatMessage(adminVenuePackageProvisioningMessages.disabled)}</StatusLabel>
      )}
    </>
  )
}

export function ReviewRow({ diff }: ReviewRowProps) {
  return (
    <HStack key={diff.name} justifyContent="space-between" alignItems="center">
      <Label width="50%" primary={diff.name} />
      <HStack spacing="s" alignItems="center">
        {typeof diff.current === 'boolean' ? <BooleanDiff value={diff.current} /> : <ListDiff values={diff.current} />}
        <h2>→</h2>
        {typeof diff.new === 'boolean' ? <BooleanDiff value={diff.new} /> : <ListDiff values={diff.new} />}
      </HStack>
    </HStack>
  )
}

export function ReviewSection({ categoryName, diffs }: ReviewSectionProps) {
  return (
    <BaseSection title={categoryName}>
      <VStack p="lm" spacing="xs">
        <Box pb="lm">
          <DividerLine margin="none" />
        </Box>
        {diffs.map(diff => (
          <ReviewRow key={diff.name} diff={diff} />
        ))}
      </VStack>
    </BaseSection>
  )
}

export function PackageProvisioningReviewForm({
  venueId,
  venueName,
  currentPackage,
  newPackage,
  selectedBetas,
  selectedAddons,
}: ProductProvisioningFormProps) {
  const { data, isLoading } = useGetVenuePackageProvisioningDiffQuery({
    venueId,
    newPackage,
    betas: selectedBetas,
    addons: selectedAddons,
  })
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const [updatePackageProvisioning] = useUpdatePackageProvisioningMutation()
  const { handleSubmit } = useFormContext() as UseForm<z.ZodType<PackageProvisioningForm>>

  const onClickUpdateProvisioning = useCallback(
    async (formData: PackageProvisioningForm) => {
      try {
        await updatePackageProvisioning({
          venueId,
          packageProvisioning: formData.packageProvisioning,
          addonApi: formData.addonApi,
          addonOnlineOrdering: formData.addonOnlineOrdering,
          addonEmailCampaigns: formData.addonEmailCampaigns,
          addonPms: formData.addonPms,
          addonSmsMarketing: formData.addonSmsMarketing,
          addonPremiumIntegrations: formData.addonPremiumIntegrations,
          betas: formData.betas,
        }).unwrap()
        notify({
          content: formatMessage(adminVenuePackageProvisioningMessages.saveSuccess),
          type: 'success',
        })
        nav.push(routes.admin.packageProvisioning, {
          params: { venueId },
        })
      } catch (error) {
        notify({ content: String(error), type: 'error' })
      }
    },
    [updatePackageProvisioning, venueId, formatMessage, nav]
  )

  return (
    <>
      <AdminPageMeta title={formatMessage(adminVenuePackageProvisioningMessages.title)} />
      {isLoading && <Loader />}
      {data && (
        <AdminPageContent
          breadcrumbs={
            <Breadcrumbs>
              <Button
                href={nav.href(routes.admin.venueEdit, { params: { venueId } })}
                variant="tertiary"
                target="_self"
                isExternal
                noPadding
                data-test="venue-configuration-button"
              >
                {formatMessage(adminVenuePackageProvisioningMessages.venueConfigurationBreadcrumb)}
              </Button>
              <Text>{formatMessage(adminVenuePackageProvisioningMessages.title)}</Text>
            </Breadcrumbs>
          }
          title={formatMessage(adminVenuePackageProvisioningMessages.reviewPageTitle)}
          description={formatMessage(adminVenuePackageProvisioningMessages.reviewPageDescription)}
          actions={
            <HStack spacing="m" alignItems="center">
              <Link to={nav.href(routes.admin.packageProvisioning, { params: { venueId } })}>Back</Link>
              <Button variant="primary" onClick={handleSubmit(onClickUpdateProvisioning)} data-test="venue-configuration-update-button">
                {formatMessage(adminVenuePackageProvisioningMessages.updateButtonText)}
              </Button>
            </HStack>
          }
        >
          <VStack pt="l" spacing="l">
            <ReviewSummary venueName={venueName} currentPackage={currentPackage} newPackage={newPackage} diffData={data} />
            {data.map(
              diffsByCategory =>
                diffsByCategory.diffs.length > 0 && (
                  <ReviewSection
                    key={diffsByCategory.categoryName}
                    categoryName={diffsByCategory.categoryName}
                    diffs={diffsByCategory.diffs}
                  />
                )
            )}
          </VStack>
        </AdminPageContent>
      )}
    </>
  )
}
