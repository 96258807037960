import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Breadcrumbs } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminVenuePackageProvisioningMessages } from '../../locales'
import { VenueGroupPackageWizardSection } from './VenueGroupPackageWizardSection'

interface VenueGroupPackageWizardProps {
  venueGroupId: string
  venueGroupName: string
}

export function VenueGroupPackageWizard({ venueGroupId, venueGroupName }: VenueGroupPackageWizardProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  return (
    <>
      <AdminPageMeta title={formatMessage(adminVenuePackageProvisioningMessages.title)} />
      <AdminPageContent
        breadcrumbs={
          <Breadcrumbs>
            <Button
              href={nav.href(routes.admin.venueGroupEdit, { params: { venueGroupId } })}
              variant="tertiary"
              target="_self"
              isExternal
              noPadding
              data-test="venue-group-configuration-button"
            >
              {formatMessage(adminVenuePackageProvisioningMessages.venueGroupConfigurationBreadcrumb)}
            </Button>
            <Text>{formatMessage(adminVenuePackageProvisioningMessages.wizardBreadcrumb)}</Text>
          </Breadcrumbs>
        }
        title={venueGroupName}
      >
        <VenueGroupPackageWizardSection venueGroupId={venueGroupId} venueGroupName={venueGroupName} />
      </AdminPageContent>
    </>
  )
}
