import { useState } from 'react'
import { JsonView, defaultStyles } from 'react-json-view-lite'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import type { AdminSyncBlobs } from '@sevenrooms/core/api'
import { useWatchMany, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Checkbox, Button } from '@sevenrooms/core/ui-kit/form'
import {
  Banner,
  BaseSection,
  Box,
  HStack,
  Loader,
  NotificationContainer,
  Section,
  SectionController,
  VStack,
  Breadcrumbs,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminSyncBlobsMessages } from '../../locales'
import 'react-json-view-lite/dist/index.css'
import { AdminSyncBlobActions } from './AdminSyncBlobActions'
import { AdminSyncBlobCounts } from './AdminSyncBlobCounts'
import { AdminSyncBlobOptions } from './AdminSyncBlobOptions'
import type { SyncBlobForm } from './AdminSyncBlob.zod'

interface AdminSyncBlobViewerProps {
  venueId: string
  venueName: string
  validModelNames: string[]
  field: Field<SyncBlobForm>
}

export function AdminSyncBlobViewer({ venueId, venueName, validModelNames, field }: AdminSyncBlobViewerProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  const [isLoading, setIsLoading] = useState(false)
  const [syncBlobs, setSyncBlobs] = useState({})
  const [counts, setCounts] = useState({})
  const [deletedCounts, setDeletedCounts] = useState({})

  const [date, modelNames, useFancyDisplay] = useWatchMany(field, ['date', 'modelNames', 'useFancyDisplay'])

  return (
    <>
      <AdminPageMeta title={formatMessage(adminSyncBlobsMessages.title)} />
      <AdminPageContent
        breadcrumbs={
          <Breadcrumbs>
            <Button
              href={nav.href(routes.admin.venueEdit, { params: { venueId } })}
              variant="tertiary"
              target="_self"
              isExternal
              noPadding
              data-test="venue-configuration-breadcrumbs-button"
            >
              {formatMessage(adminSyncBlobsMessages.venueConfigurationBreadcrumb)}
            </Button>
            <Text>{formatMessage(adminSyncBlobsMessages.syncBlobsBreadcrumb)}</Text>
          </Breadcrumbs>
        }
        title={<div dangerouslySetInnerHTML={{ __html: venueName }} />}
        actions={<AdminSyncBlobActions venueId={venueId} selectedDate={date} />}
      >
        <VStack pt="xl" spacing="m">
          <BaseSection>
            <VStack p="l" spacing="l">
              <AdminSyncBlobOptions
                {...{ venueId, isLoading, field, validModelNames, setIsLoading, setSyncBlobs, setCounts, setDeletedCounts }}
              />
              <VStack spacing="s">
                {Object.keys(syncBlobs).length === 0 && !isLoading && (
                  <Banner
                    type="attention"
                    title={formatMessage(adminSyncBlobsMessages.noDataBannerTitle)}
                    description={formatMessage(adminSyncBlobsMessages.noDataBannerDescription)}
                  />
                )}
                <SectionController behavior="multiple-sections-at-one-time">
                  <Section name="counts" title={formatMessage(adminSyncBlobsMessages.sectionCountsTitle)} initiallyCollapsed>
                    {isLoading ? (
                      <Loader />
                    ) : (
                      <AdminSyncBlobCounts
                        counts={counts}
                        deletedCounts={deletedCounts}
                        modelNames={modelNames}
                        validModelNames={validModelNames}
                      />
                    )}
                  </Section>
                </SectionController>
                <BaseSection title={formatMessage(adminSyncBlobsMessages.sectionSyncBlobsTitle)} actions={<SectionActions field={field} />}>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <VStack pt="m" spacing="m">
                      {useFancyDisplay ? (
                        /* eslint-disable-next-line react/forbid-component-props */
                        <JsonView data={syncBlobs} style={defaultStyles} />
                      ) : (
                        <BasicJsonViewer data={syncBlobs} />
                      )}
                    </VStack>
                  )}
                </BaseSection>
              </VStack>
            </VStack>
          </BaseSection>
          <NotificationContainer />
        </VStack>
      </AdminPageContent>
    </>
  )
}

interface SectionActionsProps {
  field: Field<SyncBlobForm>
}

function SectionActions({ field }: SectionActionsProps) {
  const { formatMessage } = useLocales()

  return (
    <HStack pt="s">
      <Checkbox
        field={field.prop('useFancyDisplay')}
        info={<Text color="lightFont">{formatMessage(adminSyncBlobsMessages.useFancyDisplayTooltip)}</Text>}
      >
        {formatMessage(adminSyncBlobsMessages.useFancyDisplay)}
      </Checkbox>
    </HStack>
  )
}

interface BasicJsonViewerProps {
  data: AdminSyncBlobs
}

function BasicJsonViewer({ data }: BasicJsonViewerProps) {
  return (
    <Box p="m">
      <Text whiteSpace="pre-wrap" wordBreak="break-word" fontSize="s">
        <div style={{ fontFamily: 'monospace' }}>{JSON.stringify(data, null, 2)}</div>
      </Text>
    </Box>
  )
}
