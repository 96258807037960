import { useState } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin/components'
import type { VenueGroupHistoryTrackingResponse } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { DateTime } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Box,
  Breadcrumbs,
  Table,
  TableBody,
  TableRow,
  TableCell,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminVenueHistoryTrackingMessages } from '../../locales'

interface HistoryTrackingProps {
  history: VenueGroupHistoryTrackingResponse
  venueGroupId: string
  venueGroupName: string
}

export function VenueGroupHistoryTrackingForm({ history, venueGroupId, venueGroupName }: HistoryTrackingProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const isEmpty = history.venueGroup.length === 0
  return (
    <>
      <AdminPageMeta title={formatMessage(adminVenueHistoryTrackingMessages.title)} />
      <AdminPageContent
        breadcrumbs={
          <Breadcrumbs>
            <Button
              href={nav.href(routes.admin.venueGroupEdit, { params: { venueGroupId } })}
              variant="tertiary"
              target="_self"
              isExternal
              noPadding
              data-test="admin-page-breadcrumb-button"
            >
              {formatMessage(adminVenueHistoryTrackingMessages.venueConfigurationBreadcrumb)}
            </Button>
            <Text>{formatMessage(adminVenueHistoryTrackingMessages.title)}</Text>
          </Breadcrumbs>
        }
        title={venueGroupName}
      >
        <VStack pt="m" spacing="lm">
          {isEmpty ? <Box m="l">{formatMessage(adminVenueHistoryTrackingMessages.noHistory)}</Box> : null}
          <Tabs onSelect={setSelectedIndex} selectedIndex={selectedIndex}>
            <TabList>
              <Tab data-test="venue-tab">{formatMessage(adminVenueHistoryTrackingMessages.venueTab)}</Tab>
            </TabList>
            <TabPanel>
              <Table>
                <thead>
                  <tr>
                    <TableCell isHeader isInFirstRow>
                      {formatMessage(adminVenueHistoryTrackingMessages.who)}
                    </TableCell>
                    <TableCell isHeader isInFirstRow>
                      {formatMessage(adminVenueHistoryTrackingMessages.when)}
                    </TableCell>
                    <TableCell isHeader isInFirstRow>
                      {formatMessage(adminVenueHistoryTrackingMessages.what)}
                    </TableCell>
                  </tr>
                </thead>
                <TableBody>
                  {history.venueGroup.map((el, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableRow key={index}>
                      <TableCell key="who">{el.email ? `${el.firstName} ${el.lastName} ${el.email}` : ''}</TableCell>
                      <TableCell key="when">{new DateTime(new Date(el.created)).formatNYearNMonthNDaySTime()}</TableCell>
                      <TableCell key="what">{el.systemText}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabPanel>
          </Tabs>
        </VStack>
      </AdminPageContent>
    </>
  )
}
