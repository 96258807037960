import type { Field, z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, Label } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { EmailEditor, BodyContentWrapper } from '@sevenrooms/marketing'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'
import type { adminOneTimeEmailCampaignTemplateDesignForm } from './adminOneTimeEmailCampaignTemplateDesignForm'

export interface AdminOneTimeEmailCampaignTemplateDesignProps {
  field: Field<z.infer<typeof adminOneTimeEmailCampaignTemplateDesignForm>>
  disabled: boolean
  isDirty: boolean
  onEditorInit: Function
}

export function AdminOneTimeEmailCampaignTemplateDesign({
  disabled,
  field,
  isDirty,
  onEditorInit,
}: AdminOneTimeEmailCampaignTemplateDesignProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  return (
    <BaseSection title={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.templateDesign)}>
      <Box p="lm">
        <VStack>
          <HStack justifyContent="space-between" pb="m">
            <VStack>
              <Label
                primary={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.designYourEmailContent)}
                secondary={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.designYourEmailContentDescription)}
              />
            </VStack>

            <Button
              variant="secondary"
              size="m"
              data-test="email-campaign-preview-send-test-email-modal-button"
              onClick={() => {
                nav.push(routes.admin.oneTimeEmailCampaignsTemplates.sendTestEmailModal)
              }}
            >
              {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.sendTestEmailButtonText)}
            </Button>
          </HStack>

          <BodyContentWrapper disable={disabled}>
            <EmailEditor
              hasDesktopView
              field={field.prop('emailBodyParts')}
              disabled={disabled}
              isDirty={isDirty}
              venue={undefined}
              venueProfile={undefined}
              destination={routes.admin.oneTimeEmailCampaignsTemplates}
              data-test="emailBodyParts"
              onEditorInit={onEditorInit}
            />
          </BodyContentWrapper>
        </VStack>
      </Box>
    </BaseSection>
  )
}
