import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/utils'
import { VenuePackageProvisioningContainer, VenuePackageWizard, VenueGroupPackageWizard } from './components'

function renderVenuePackageProvisioning(id: HTMLElement, venueId: string, venueName: string) {
  return render(
    <AdminPage>
      <VenuePackageProvisioningContainer venueId={venueId} venueName={venueName} />
    </AdminPage>,
    id
  )
}

function renderVenuePackageWizard(id: HTMLElement, venueId: string, venueName: string, venueGroupId: string, venueGroupName: string) {
  return render(
    <AdminPage>
      <VenuePackageWizard venueId={venueId} venueName={venueName} venueGroupId={venueGroupId} venueGroupName={venueGroupName} />
    </AdminPage>,
    id
  )
}

function renderVenueGroupPackageWizard(id: HTMLElement, venueGroupId: string, venueGroupName: string) {
  return render(
    <AdminPage>
      <VenueGroupPackageWizard venueGroupId={venueGroupId} venueGroupName={venueGroupName} />
    </AdminPage>,
    id
  )
}

svrExport('AdminPackageProvisioning', 'render', renderVenuePackageProvisioning)
svrExport('AdminVenuePackageWizard', 'render', renderVenuePackageWizard)
svrExport('AdminVenueGroupPackageWizard', 'render', renderVenueGroupPackageWizard)
