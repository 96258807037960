import FroalaEditor from 'froala-editor'
import { useState } from 'react'
import { ADMIN_EMAIL_CAMPAIGN_DEFAULT, MORE_PARAGRAPH_BUTTONS } from '@sevenrooms/core/domain/constants'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Box, Window } from '@sevenrooms/core/ui-kit/layout'
import { defaultConfig, doInsertLink, FormEditor, plusBoxMultiplePath } from '@sevenrooms/core/ui-kit/optional'
import { emailBuilderMessages, Unsubscribe, Reservation, Menu, campaignBuilderMessages } from '@sevenrooms/marketing'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'

export interface AdminEmailEditorProps<T extends string | null> {
  field: Field<T>
  buttons?: string[]
  config?: object
  'data-test': string
}

export function AdminEmailEditor<T extends string | null>({ field, buttons, config, ...props }: AdminEmailEditorProps<T>) {
  const [editor, setEditor] = useState(null)
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueSettings } = useAppContext()

  const doLinkInsertion = (link: { text: string; link: string }) => {
    doInsertLink(editor, link)
  }

  FroalaEditor.DefineIcon('embed_dropdown', { NAME: 'Embed', PATH: plusBoxMultiplePath })

  FroalaEditor.RegisterCommand('embed_dropdown', {
    title: 'Embed',
    type: 'dropdown',
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: {
      reservation: formatMessage(campaignBuilderMessages.reservation),
      unsubscribe: formatMessage(emailBuilderMessages.unsubscribe),
      menu: formatMessage(campaignBuilderMessages.menu),
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback(this: any, _: string, val: string) {
      setEditor(this)
      // eslint-disable-next-line react/no-this-in-sfc
      this.selection.save()
      switch (val) {
        case 'reservation':
          nav.push(routes.admin.ongoingEmailCampaignTemplates.create.reservationModal)
          break
        case 'unsubscribe':
          nav.push(routes.admin.ongoingEmailCampaignTemplates.create.unsubscribeModal)
          break
        case 'menu':
          nav.push(routes.admin.ongoingEmailCampaignTemplates.create.menuModal)
          break
        default:
      }
    },
  })

  const editorConfig = {
    ...defaultConfig,
    width: 450,
    useClasses: false,
    toolbarButtons: {
      moreMisc: {
        buttons: buttons || ADMIN_EMAIL_CAMPAIGN_DEFAULT,
        buttonsVisible: 7,
      },
      moreParagraph: {
        buttons: buttons ? [] : MORE_PARAGRAPH_BUTTONS,
        buttonsVisible: 0,
      },
    },
    toolbarButtonsSM: {
      moreMisc: {
        buttons: buttons || ADMIN_EMAIL_CAMPAIGN_DEFAULT,
        buttonsVisible: 7,
      },
      moreParagraph: {
        buttons: buttons ? [] : MORE_PARAGRAPH_BUTTONS,
        buttonsVisible: 0,
      },
    },
    emoticonsUseImage: false,
    events: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      click(e: any) {
        e.preventDefault()
      },
    },
    ...config,
  }

  return (
    <Box>
      <FormEditor
        field={field}
        config={editorConfig}
        isLoyaltyAndPerksEnabled={venueSettings?.isLoyaltyAndPerksEnabled}
        referralProgramEnabled={venueSettings?.referralProgramEnabled}
        {...props}
      />
      <Surface destination={routes.admin.ongoingEmailCampaignTemplates.create.unsubscribeModal}>
        <Window>
          <Unsubscribe
            destination={routes.admin.ongoingEmailCampaignTemplates.create}
            insertLink={doLinkInsertion}
            closeHref={nav.closeSurfaceHref(routes.admin.ongoingEmailCampaignTemplates.create.unsubscribeModal)}
          />
        </Window>
      </Surface>
      <Surface destination={routes.admin.ongoingEmailCampaignTemplates.create.reservationModal}>
        <Window>
          <Reservation
            destination={routes.admin.ongoingEmailCampaignTemplates.create}
            insertLink={doLinkInsertion}
            closeHref={nav.closeSurfaceHref(routes.admin.ongoingEmailCampaignTemplates.create.reservationModal)}
          />
        </Window>
      </Surface>
      <Surface destination={routes.admin.ongoingEmailCampaignTemplates.create.menuModal}>
        <Window>
          <Menu
            destination={routes.admin.ongoingEmailCampaignTemplates.create}
            insertLink={doLinkInsertion}
            closeHref={nav.closeSurfaceHref(routes.admin.ongoingEmailCampaignTemplates.create.menuModal)}
          />
        </Window>
      </Surface>
    </Box>
  )
}
