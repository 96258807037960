import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { Switch, Route } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { svrExport } from '@sevenrooms/core/utils'
import { AdminVenueGroupMerge } from './components'

function renderVenueGroupMergeView(id: string) {
  return render(
    <AdminPage>
      <Switch>
        <Route path={routes.admin.venueGroupMerge.path}>
          <AdminVenueGroupMerge />
        </Route>
      </Switch>
    </AdminPage>,
    document.getElementById(id)
  )
}

svrExport('VenueGroupMerge', 'render', renderVenueGroupMergeView)
