import _ from 'lodash'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import type { AutoTagTierConfig, AutoTagTierAttributes } from '@sevenrooms/core/api'
import {
  type AutoTagConfigType,
  AutoTagConfigTypeEnum,
  type AutoTagTierAttributeType,
  AutoTagTierAttributeTypeEnum,
  type AutoTagTierType,
  AutoTagTierTypeEnum,
} from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Button, IconButton } from '@sevenrooms/core/ui-kit/form'
import { Box, SlideOutSubsection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { defaultAutoTagTierEditorMessages } from '../../../../../locales'
import { addTierConfig, removeTierConfig } from '../../autoTagSlideOutSlice'
import { DefaultAutoTagNumberInput } from './DefaultAutoTagNumberInput'
import { DefaultAutoTagSelect } from './DefaultAutoTagSelect'
import { DefaultAutoTagTierNameInput } from './DefaultAutoTagTierNameInput'

const spendSelectOptions: SelectOption[] = [
  { id: 'TOTAL', label: 'total spend' },
  { id: 'AVERAGE_PER_COVER', label: 'average spend per cover' },
  { id: 'AVERAGE_PER_VISIT', label: 'average spend per visit' },
]

const reservationRequestSourceOptions: SelectOption[] = [
  { id: 'SEVENROOMS', label: 'Sevenrooms' },
  { id: 'BOOKIE', label: 'Bookie' },
]

const addRemovableConfigTypes = new Set<AutoTagConfigType>([
  AutoTagConfigTypeEnum.VISITS_GLOBAL,
  AutoTagConfigTypeEnum.VISITS_LOCAL,
  AutoTagConfigTypeEnum.SPEND_GLOBAL,
  AutoTagConfigTypeEnum.SPEND_LOCAL,
])

const getMessageValues = ({
  index,
  attributes,
  valueOrder,
  tierConfigs,
  isSelected,
}: {
  index: number
  attributes: AutoTagTierAttributes
  valueOrder: (AutoTagTierAttributeType | AutoTagTierType)[]
  tierConfigs: AutoTagTierConfig[]
  isSelected: boolean
}) =>
  valueOrder.map(value => {
    if (value in AutoTagTierAttributeTypeEnum) {
      const attribute = value as AutoTagTierAttributeType
      if (attribute === AutoTagTierAttributeTypeEnum.SPEND_TYPE) {
        return isSelected ? (
          <DefaultAutoTagSelect
            key={attribute}
            index={index}
            attribute={attribute}
            initialValue={attributes[attribute]}
            options={spendSelectOptions}
          />
        ) : (
          <Text key={attribute} fontWeight="bold">
            {spendSelectOptions.find(selectOption => selectOption.id === attributes[attribute])?.label}
          </Text>
        )
      } else if (attribute === AutoTagTierAttributeTypeEnum.RESERVATION_REQUEST_SOURCE) {
        return isSelected ? (
          <DefaultAutoTagSelect
            key={attribute}
            index={index}
            attribute={attribute}
            initialValue={attributes[attribute]}
            options={reservationRequestSourceOptions}
          />
        ) : (
          <Text key={attribute} fontWeight="bold">
            {reservationRequestSourceOptions.find(sourceOption => sourceOption.id === attributes[attribute])?.label}
          </Text>
        )
      }
      return isSelected ? (
        <DefaultAutoTagNumberInput key={attribute} index={index} attribute={attribute} initialValue={attributes[attribute] as number} />
      ) : (
        <Text key={attribute} fontWeight="bold">
          {attributes[attribute]}
        </Text>
      )
    }
    const siblingTierDisplayName = tierConfigs.find(tierConfig => tierConfig.tierType === value)?.nameDisplay
    return <Text key={siblingTierDisplayName}>{siblingTierDisplayName}</Text>
  })

const getTierContent = (index: number, tierConfig: AutoTagTierConfig, isSelected: boolean, tierConfigs: AutoTagTierConfig[]) => {
  const { attributes, tierType } = tierConfig
  let attributeOrder: (AutoTagTierAttributeType | AutoTagTierType)[]
  switch (tierType) {
    case AutoTagTierTypeEnum.VISITS_GLOBAL_TIER:
      attributeOrder = [
        AutoTagTierAttributeTypeEnum.MIN_VISITS,
        AutoTagTierAttributeTypeEnum.PAST_MONTHS_MAX,
        AutoTagTierAttributeTypeEnum.MIN_VENUES,
      ]
      break
    case AutoTagTierTypeEnum.VISITS_LOCAL_TIER:
      attributeOrder = [AutoTagTierAttributeTypeEnum.MIN_VISITS, AutoTagTierAttributeTypeEnum.PAST_MONTHS_MAX]
      break
    case AutoTagTierTypeEnum.FIRST_TIMER_COMPLETED:
    case AutoTagTierTypeEnum.FIRST_TIMER_COMPLETED_GLOBAL:
      attributeOrder = [AutoTagTierAttributeTypeEnum.REMOVE_AFTER_DAYS]
      break
    case AutoTagTierTypeEnum.POSITIVE_REVIEW:
    case AutoTagTierTypeEnum.NEGATIVE_REVIEW:
    case AutoTagTierTypeEnum.POSITIVE_FEEDBACK:
    case AutoTagTierTypeEnum.NEGATIVE_FEEDBACK:
    case AutoTagTierTypeEnum.GROUP_REVIEW:
    case AutoTagTierTypeEnum.GROUP_FEEDBACK:
      attributeOrder = [AutoTagTierAttributeTypeEnum.PAST_MONTHS_MAX]
      break
    case AutoTagTierTypeEnum.WE_MISS_YOU:
      attributeOrder = [AutoTagTierAttributeTypeEnum.PAST_MONTHS_MIN, AutoTagTierAttributeTypeEnum.PAST_MONTHS_MAX]
      break
    case AutoTagTierTypeEnum.WELCOME_BACK:
      attributeOrder = [AutoTagTierTypeEnum.WE_MISS_YOU]
      break
    case AutoTagTierTypeEnum.WELCOME_BACK_COMPLETED:
      attributeOrder = [AutoTagTierTypeEnum.WELCOME_BACK, AutoTagTierAttributeTypeEnum.REMOVE_AFTER_DAYS]
      break
    case AutoTagTierTypeEnum.WE_MISS_YOU_GLOBAL:
      attributeOrder = [
        AutoTagTierAttributeTypeEnum.PAST_MONTHS_MIN,
        AutoTagTierAttributeTypeEnum.PAST_MONTHS_MAX,
        AutoTagTierAttributeTypeEnum.MIN_VENUES,
      ]
      break
    case AutoTagTierTypeEnum.WELCOME_BACK_GLOBAL:
      attributeOrder = [AutoTagTierTypeEnum.WE_MISS_YOU_GLOBAL]
      break
    case AutoTagTierTypeEnum.WELCOME_BACK_COMPLETED_GLOBAL:
      attributeOrder = [AutoTagTierTypeEnum.WELCOME_BACK_GLOBAL, AutoTagTierAttributeTypeEnum.REMOVE_AFTER_DAYS]
      break
    case AutoTagTierTypeEnum.ANNIVERSARY_LOCAL:
      attributeOrder = [AutoTagTierAttributeTypeEnum.DAY_DELTA, AutoTagTierTypeEnum.CELEBRATED_ANNIVERSARY_LOCAL]
      break
    case AutoTagTierTypeEnum.ANNIVERSARY:
      attributeOrder = [AutoTagTierAttributeTypeEnum.DAY_DELTA, AutoTagTierTypeEnum.CELEBRATED_ANNIVERSARY]
      break
    case AutoTagTierTypeEnum.CELEBRATED_ANNIVERSARY_LOCAL:
      attributeOrder = [AutoTagTierTypeEnum.ANNIVERSARY_LOCAL, AutoTagTierAttributeTypeEnum.REMOVE_AFTER_DAYS]
      break
    case AutoTagTierTypeEnum.CELEBRATED_ANNIVERSARY:
      attributeOrder = [AutoTagTierTypeEnum.ANNIVERSARY, AutoTagTierAttributeTypeEnum.REMOVE_AFTER_DAYS]
      break
    case AutoTagTierTypeEnum.SPEND_LOCAL_TIER:
    case AutoTagTierTypeEnum.SPEND_GLOBAL_TIER:
      attributeOrder = [
        AutoTagTierAttributeTypeEnum.MIN_VISITS,
        AutoTagTierAttributeTypeEnum.SPEND_TYPE,
        AutoTagTierAttributeTypeEnum.SPEND_AMOUNT,
      ]
      break
    case AutoTagTierTypeEnum.BIRTHDAY_LOCAL:
      attributeOrder = [AutoTagTierAttributeTypeEnum.DAY_DELTA, AutoTagTierTypeEnum.CELEBRATED_BIRTHDAY_LOCAL]
      break
    case AutoTagTierTypeEnum.BIRTHDAY:
      attributeOrder = [AutoTagTierAttributeTypeEnum.DAY_DELTA, AutoTagTierTypeEnum.CELEBRATED_BIRTHDAY]
      break
    case AutoTagTierTypeEnum.CELEBRATED_BIRTHDAY_LOCAL:
      attributeOrder = [AutoTagTierTypeEnum.BIRTHDAY_LOCAL, AutoTagTierAttributeTypeEnum.REMOVE_AFTER_DAYS]
      break
    case AutoTagTierTypeEnum.CELEBRATED_BIRTHDAY:
      attributeOrder = [AutoTagTierTypeEnum.BIRTHDAY, AutoTagTierAttributeTypeEnum.REMOVE_AFTER_DAYS]
      break
    case AutoTagTierTypeEnum.REFERRER_TIER:
      attributeOrder = [AutoTagTierAttributeTypeEnum.MIN_REFERRALS]
      break
    case AutoTagTierTypeEnum.VISITS_LOCAL_MARKETING_SEGMENTATION_TIER:
      attributeOrder = [AutoTagTierAttributeTypeEnum.MIN_VISITS]
      break
    case AutoTagTierTypeEnum.FIRST_TIMER:
    case AutoTagTierTypeEnum.FIRST_TIMER_GLOBAL:
    case AutoTagTierTypeEnum.BOOKED_THROUGH_OPENTABLE:
    case AutoTagTierTypeEnum.NO_SHOW:
    case AutoTagTierTypeEnum.WAITLIST_ABANDON:
    case AutoTagTierTypeEnum.CANCELLATION:
    case AutoTagTierTypeEnum.UPCOMING_RESERVATION_IN_30_DAYS:
    case AutoTagTierTypeEnum.RESERVATION_REQUESTS:
      attributeOrder = [
        AutoTagTierAttributeTypeEnum.MIN_RESERVATION_REQUESTS,
        AutoTagTierAttributeTypeEnum.RESERVATION_REQUEST_SOURCE,
        AutoTagTierAttributeTypeEnum.MIN_RESERVATION_REQUEST_PARTY_SIZE,
        AutoTagTierAttributeTypeEnum.PAST_DAYS_MAX,
      ]
      break
    default:
      attributeOrder = []
      break
  }
  return {
    template: defaultAutoTagTierEditorMessages[tierType],
    messageValues: getMessageValues({ index, attributes, valueOrder: attributeOrder, isSelected, tierConfigs }),
  }
}

export function DefaultAutoTagTierEditor({
  tierConfigs,
  initialSelected,
  configType,
}: {
  tierConfigs: AutoTagTierConfig[]
  initialSelected: string
  configType: AutoTagConfigType
}) {
  const [selected, setSelected] = useState<number>(tierConfigs.findIndex(tierConfig => tierConfig.nameDisplay === initialSelected))
  const dispatch = useDispatch()
  const { formatMessage } = useLocales()

  const isAddRemovable = addRemovableConfigTypes.has(configType)

  return (
    <>
      {tierConfigs.map((tierConfig, index) => {
        const isSelected = index === selected
        const { template, messageValues } = getTierContent(index, tierConfig, isSelected, tierConfigs)
        return (
          <SlideOutSubsection
            key={_.uniqueId()}
            title={
              isSelected ? (
                <Box width="450px">
                  <DefaultAutoTagTierNameInput index={index} tierConfigs={tierConfigs} />
                </Box>
              ) : (
                tierConfig.nameDisplay
              )
            }
            collapsed={!isSelected}
            onClick={() => (isSelected ? null : setSelected(index))}
            actions={
              isAddRemovable ? (
                <IconButton borderType="none-round" icon="VMSWeb-close" onClick={() => dispatch(removeTierConfig(index))} />
              ) : undefined
            }
          >
            <Box pt="m" pr="lm" pb="m" pl="lm">
              <Text>{formatMessage(template, messageValues)}</Text>
            </Box>
          </SlideOutSubsection>
        )
      })}
      {isAddRemovable && (
        <SlideOutSubsection>
          <Box pt="m" pr="lm" pb="m" pl="lm">
            <Button
              data-test="add-tier-button"
              variant="secondary"
              icon="VMSWeb-add"
              onClick={() => {
                dispatch(addTierConfig(configType))
              }}
            >
              {formatMessage(defaultAutoTagTierEditorMessages.addTier)}
            </Button>
          </Box>
        </SlideOutSubsection>
      )}
    </>
  )
}
