import _ from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'
import { AdminPageContent, AdminPageMeta } from '@sevenrooms/admin'
import type { OneTimeEmailCampaignTemplate, NewEmailCampaignTemplate } from '@sevenrooms/core/api'
import {
  type MarketingCampaignProductType,
  type MarketingCampaignRegion,
  MarketingCampaignRegionEnum,
  OneTimeEmailCampaignStatusEnum,
  MarketingCampaignTypeEnum,
} from '@sevenrooms/core/domain'
import { type Field, useForm, type z } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { Banner, HStack, Window, notify, VStack, UnsavedChangesModal } from '@sevenrooms/core/ui-kit/layout'
import { SendTestEmailModal, generateEmailCampaignPreviewTemplate, useOneTimeEmailCampaignTemplateCategories } from '@sevenrooms/marketing'
import { AdminOneTimeEmailCampaignTemplatesTabs } from '../../const'
import { useAdminEmailCampaignTemplateForm } from '../../hooks'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'
import { AdminOneTimeEmailCampaignTemplateActions } from '../AdminOneTimeEmailCampaignTemplateActions'
import { AdminOneTimeEmailCampaignTemplateArchiveModal } from '../AdminOneTimeEmailCampaignTemplateArchiveModal'
import { AdminOneTimeEmailCampaignTemplateDeactivateModal } from '../AdminOneTimeEmailCampaignTemplateDeactivateModal'
import {
  AdminOneTimeEmailCampaignTemplateDesign,
  type adminOneTimeEmailCampaignTemplateDesignForm,
} from '../AdminOneTimeEmailCampaignTemplateDesign'
import {
  AdminOneTimeEmailCampaignTemplateDetails,
  type adminOneTimeEmailCampaignTemplateDetailsForm,
} from '../AdminOneTimeEmailCampaignTemplateDetails'
import { AdminOneTimeEmailCampaignTemplateDuplicateModal } from '../AdminOneTimeEmailCampaignTemplateDuplicateModal'
import { AdminOneTimeEmailCampaignTemplatePublishModal } from '../AdminOneTimeEmailCampaignTemplatePublishModal'
import { AdminOneTimeEmailCampaignTemplateSaveDeactivateModal } from '../AdminOneTimeEmailCampaignTemplateSaveDeactivateModal'
import { AdminOneTimeEmailCampaignTemplateUnarchiveModal } from '../AdminOneTimeEmailCampaignTemplateUnarchiveModal'

export interface AdminOneTimeEmailCampaignTemplateFormProps {
  isViewMode: boolean
  isUpdating: boolean
  emailCampaignTemplate?: OneTimeEmailCampaignTemplate
  onEmailCampaignTemplateCreate: (emailCampaignTemplate: NewEmailCampaignTemplate) => Promise<OneTimeEmailCampaignTemplate>
  onEmailCampaignTemplateUpdate: (emailCampaignTemplate: OneTimeEmailCampaignTemplate) => Promise<OneTimeEmailCampaignTemplate[]>
  duplicateEmailCampaignId: string | null
  getEmailCampaignTemplateId: string | null
}

export function AdminOneTimeEmailCampaignTemplateForm({
  isViewMode,
  isUpdating,
  emailCampaignTemplate,
  onEmailCampaignTemplateCreate,
  onEmailCampaignTemplateUpdate,
  duplicateEmailCampaignId,
  getEmailCampaignTemplateId,
}: AdminOneTimeEmailCampaignTemplateFormProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const emailCampaignTemplateId = getEmailCampaignTemplateId || duplicateEmailCampaignId
  const categories = useOneTimeEmailCampaignTemplateCategories()
  const formValues = useAdminEmailCampaignTemplateForm()

  const defaultValues = useMemo(() => {
    if (emailCampaignTemplate) {
      const origin = emailCampaignTemplate.unpublishedChanges ?? emailCampaignTemplate

      return {
        campaignName: `${duplicateEmailCampaignId ? `${formatMessage(adminOneTimeEmailCampaignTemplatesMessages.copyOf)} ` : ''}${
          origin.campaignName
        }`,
        templateCategories: origin?.templateCategories ?? [],
        emailBodyParts: _.head(origin?.emailBodyParts) ?? '',
      }
    }

    return undefined
  }, [emailCampaignTemplate, duplicateEmailCampaignId, formatMessage])

  const form = useForm<typeof formValues>(formValues, {
    defaultValues,
  })

  const {
    field,
    getValues,
    reset,
    formState: { isDirty, isValid },
    trigger,
  } = form

  const getFormValues = useCallback(() => {
    const formValues = getValues()

    return {
      ...formValues,
      campaignName: formValues.campaignName ?? '',
      emailSubject: 'N/A',
      emailSender: 'N/A',
      campaignProductType: 'ON_PREM' as MarketingCampaignProductType,
      campaignRegion: MarketingCampaignRegionEnum.NORTH_AMERICA as MarketingCampaignRegion,
      campaignType: MarketingCampaignTypeEnum.ONE_TIME,
      emailBodyParts: formValues.emailBodyParts ? [formValues.emailBodyParts] : [],
      emailBodyPartsConfig: formValues.emailBodyParts ? [{ value: formValues.emailBodyParts, isEditable: true, maxLength: null }] : [],
      templateCategories: formValues?.templateCategories ?? [],
    }
  }, [getValues])

  const onPublish = useCallback(async () => {
    const values = getValues()

    if (getEmailCampaignTemplateId && emailCampaignTemplate) {
      try {
        await onEmailCampaignTemplateUpdate({
          ...emailCampaignTemplate,
          ...emailCampaignTemplate.unpublishedChanges,
          ...getFormValues(),
          status: OneTimeEmailCampaignStatusEnum.ACTIVE,
          unpublishedChanges: null,
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.publishSuccess, { title: values?.campaignName }),
          type: 'success',
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
          queryMode: 'replace',
          query: {
            tab: AdminOneTimeEmailCampaignTemplatesTabs.ACTIVE,
          },
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.publishError, { title: values?.campaignName }),
          type: 'error',
        })
      }
    } else {
      try {
        await onEmailCampaignTemplateCreate({
          ..._.omit(emailCampaignTemplate, ['created', 'updated']),
          ...getFormValues(),
          status: OneTimeEmailCampaignStatusEnum.ACTIVE,
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
          queryMode: 'replace',
          query: {
            tab: AdminOneTimeEmailCampaignTemplatesTabs.ACTIVE,
          },
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.publishSuccess, { title: values?.campaignName }),
          type: 'success',
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.publishError, { title: values?.campaignName }),
          type: 'error',
        })
      }
    }
  }, [
    nav,
    emailCampaignTemplate,
    formatMessage,
    onEmailCampaignTemplateUpdate,
    getValues,
    onEmailCampaignTemplateCreate,
    getEmailCampaignTemplateId,
    getFormValues,
  ])

  const onArchive = useCallback(async () => {
    if (emailCampaignTemplateId && emailCampaignTemplate) {
      const values = getValues()

      try {
        await onEmailCampaignTemplateUpdate({
          ...emailCampaignTemplate,
          status: OneTimeEmailCampaignStatusEnum.INACTIVE,
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.archiveSuccess, { title: values?.campaignName }),
          type: 'success',
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
          queryMode: 'replace',
          query: {
            tab: AdminOneTimeEmailCampaignTemplatesTabs.INACTIVE,
          },
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.archiveError, { title: values?.campaignName }),
          type: 'error',
        })
      }
    }
  }, [emailCampaignTemplate, emailCampaignTemplateId, formatMessage, nav, onEmailCampaignTemplateUpdate, getValues])

  const onUnarchive = useCallback(async () => {
    if (emailCampaignTemplateId && emailCampaignTemplate) {
      const values = getValues()

      try {
        await onEmailCampaignTemplateUpdate({
          ...emailCampaignTemplate,
          status: OneTimeEmailCampaignStatusEnum.DRAFT,
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.unarchiveSuccess, { title: values?.campaignName }),
          type: 'success',
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates.edit, {
          params: { templateId: emailCampaignTemplate.id },
          queryMode: 'clear',
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.archiveSuccess, { title: values?.campaignName }),
          type: 'error',
        })
      }
    }
  }, [emailCampaignTemplate, emailCampaignTemplateId, formatMessage, nav, onEmailCampaignTemplateUpdate, getValues])

  const onDeactivate = useCallback(async () => {
    if (emailCampaignTemplate && emailCampaignTemplateId) {
      const values = getValues()

      if (isDirty) {
        nav.push(routes.admin.oneTimeEmailCampaignsTemplates.edit.saveDeactivateModal, {
          params: { templateId: emailCampaignTemplateId },
          queryMode: 'clear',
        })
      } else {
        try {
          await onEmailCampaignTemplateUpdate({
            ...emailCampaignTemplate,
            status: OneTimeEmailCampaignStatusEnum.INACTIVE,
          })

          notify({
            content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.deactivateSuccess, { title: values?.campaignName }),
            type: 'success',
          })

          nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
            queryMode: 'replace',
            query: {
              tab: AdminOneTimeEmailCampaignTemplatesTabs.INACTIVE,
            },
          })
        } catch {
          notify({
            content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.deactivateError, { title: values?.campaignName }),
            type: 'error',
          })
        }
      }
    }
  }, [emailCampaignTemplate, emailCampaignTemplateId, formatMessage, nav, onEmailCampaignTemplateUpdate, getValues, isDirty])

  const onSaveDeactivate = useCallback(async () => {
    if (emailCampaignTemplateId && emailCampaignTemplate) {
      const values = getValues()

      try {
        await onEmailCampaignTemplateUpdate({
          ...emailCampaignTemplate,
          status: OneTimeEmailCampaignStatusEnum.INACTIVE,
          unpublishedChanges: getFormValues(),
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.deactivateSuccess, { title: values?.campaignName }),
          type: 'success',
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
          queryMode: 'replace',
          query: {
            tab: AdminOneTimeEmailCampaignTemplatesTabs.INACTIVE,
          },
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.deactivateError, { title: values?.campaignName }),
          type: 'error',
        })
      }
    }
  }, [emailCampaignTemplate, emailCampaignTemplateId, formatMessage, nav, getValues, onEmailCampaignTemplateUpdate, getFormValues])

  const onDiscardDeactivate = useCallback(async () => {
    if (emailCampaignTemplateId && emailCampaignTemplate) {
      const values = getValues()

      try {
        await onEmailCampaignTemplateUpdate({
          ...emailCampaignTemplate,
          status: OneTimeEmailCampaignStatusEnum.INACTIVE,
          unpublishedChanges: null,
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.deactivateSuccess, { title: values?.campaignName }),
          type: 'success',
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
          queryMode: 'replace',
          query: {
            tab: AdminOneTimeEmailCampaignTemplatesTabs.INACTIVE,
          },
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.deactivateError, { title: values?.campaignName }),
          type: 'error',
        })
      }
    }
  }, [emailCampaignTemplate, emailCampaignTemplateId, formatMessage, nav, onEmailCampaignTemplateUpdate, getValues])

  const onSaveInAdmin = useCallback(async () => {
    if (emailCampaignTemplateId && emailCampaignTemplate) {
      const values = getValues()

      try {
        await onEmailCampaignTemplateUpdate({
          ...emailCampaignTemplate,
          unpublishedChanges: getFormValues(),
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveSuccess, { title: values?.campaignName }),
          type: 'success',
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
          queryMode: 'replace',
          query: {
            tab:
              Object.values(AdminOneTimeEmailCampaignTemplatesTabs).find(
                tab => (emailCampaignTemplate.status as unknown as AdminOneTimeEmailCampaignTemplatesTabs) === tab
              ) ?? '',
          },
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveError, { title: values?.campaignName }),
          type: 'error',
        })
      }
    }
  }, [emailCampaignTemplate, emailCampaignTemplateId, formatMessage, nav, onEmailCampaignTemplateUpdate, getValues, getFormValues])

  const onDuplicateSaveInAdmin = useCallback(async () => {
    if (emailCampaignTemplateId && emailCampaignTemplate) {
      const values = getValues()

      try {
        await onEmailCampaignTemplateUpdate({
          ...emailCampaignTemplate,
          unpublishedChanges: getFormValues(),
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveSuccess, { title: values?.campaignName }),
          type: 'success',
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates.duplicate, {
          params: { templateId: emailCampaignTemplateId },
          queryMode: 'clear',
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveError, { title: values?.campaignName }),
          type: 'error',
        })
      }
    }
  }, [emailCampaignTemplate, emailCampaignTemplateId, formatMessage, nav, onEmailCampaignTemplateUpdate, getValues, getFormValues])

  const onDuplicatePublish = useCallback(async () => {
    const values = getValues()

    if (getEmailCampaignTemplateId && emailCampaignTemplate) {
      try {
        await onEmailCampaignTemplateUpdate({
          ...emailCampaignTemplate,
          ...emailCampaignTemplate.unpublishedChanges,
          ...getFormValues(),
          status: OneTimeEmailCampaignStatusEnum.ACTIVE,
          unpublishedChanges: null,
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.publishSuccess, { title: values?.campaignName }),
          type: 'success',
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates.duplicate, {
          params: { templateId: getEmailCampaignTemplateId },
          queryMode: 'clear',
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.publishError, { title: values?.campaignName }),
          type: 'error',
        })
      }
    }
  }, [nav, emailCampaignTemplate, formatMessage, onEmailCampaignTemplateUpdate, getValues, getEmailCampaignTemplateId, getFormValues])

  const onSaveAsDraft = useCallback(async () => {
    const values = getValues()

    if (getEmailCampaignTemplateId && emailCampaignTemplate) {
      try {
        await onEmailCampaignTemplateUpdate({
          ...emailCampaignTemplate,
          ...getFormValues(),
          status: OneTimeEmailCampaignStatusEnum.DRAFT,
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
          queryMode: 'replace',
          query: {
            tab: AdminOneTimeEmailCampaignTemplatesTabs.DRAFT,
          },
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveSuccess, { title: values?.campaignName }),
          type: 'success',
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveError, { title: values?.campaignName }),
          type: 'error',
        })
      }
    } else {
      try {
        await onEmailCampaignTemplateCreate({
          ..._.omit(emailCampaignTemplate, ['created', 'updated']),
          ...getFormValues(),
          status: OneTimeEmailCampaignStatusEnum.DRAFT,
        })

        nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
          queryMode: 'replace',
          query: {
            tab: AdminOneTimeEmailCampaignTemplatesTabs.DRAFT,
          },
        })

        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveSuccess, { title: values?.campaignName }),
          type: 'success',
        })
      } catch {
        notify({
          content: formatMessage(adminOneTimeEmailCampaignTemplatesMessages.saveError, { title: values?.campaignName }),
          type: 'error',
        })
      }
    }
  }, [
    emailCampaignTemplate,
    getEmailCampaignTemplateId,
    formatMessage,
    nav,
    onEmailCampaignTemplateUpdate,
    onEmailCampaignTemplateCreate,
    getFormValues,
    getValues,
  ])

  const onEditorInit = useCallback(() => {
    const values = getValues()
    reset(values)
  }, [reset, getValues])

  function getTitle() {
    if (emailCampaignTemplate) {
      const { status, unpublishedChanges, campaignName } = emailCampaignTemplate

      if (!duplicateEmailCampaignId) {
        return `${formatMessage(status === OneTimeEmailCampaignStatusEnum.INACTIVE ? commonMessages.view : commonMessages.edit)} ${
          unpublishedChanges?.campaignName || campaignName
        }`
      }
    }

    return formatMessage(adminOneTimeEmailCampaignTemplatesMessages.createNewTemplate)
  }

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  return (
    <>
      <Form {...form} onSubmit={_.noop} onInvalid={_.noop}>
        <AdminPageMeta title={getTitle()} />

        <AdminPageContent
          title={getTitle()}
          actions={
            <HStack spacing="s">
              <AdminOneTimeEmailCampaignTemplateActions
                onSaveAsDraft={onSaveAsDraft}
                emailCampaignTemplate={emailCampaignTemplate}
                disabled={!isValid || isUpdating}
                onSaveInAdmin={onSaveInAdmin}
                isDirty={isDirty}
                isDuplicate={!!duplicateEmailCampaignId}
              />
            </HStack>
          }
        >
          <VStack width="100%" spacing="lm" mt="lm">
            {emailCampaignTemplate?.status === OneTimeEmailCampaignStatusEnum.ACTIVE && !duplicateEmailCampaignId && (
              <Banner
                type="warning"
                title={formatMessage(commonMessages.warning)}
                description={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.updateWarningBanner)}
              />
            )}

            <AdminOneTimeEmailCampaignTemplateDetails
              emailCampaignTemplate={emailCampaignTemplate}
              categories={categories}
              disabled={isViewMode || isUpdating}
              field={field as unknown as Field<z.infer<typeof adminOneTimeEmailCampaignTemplateDetailsForm>>}
              isDuplicate={!!duplicateEmailCampaignId}
            />

            <AdminOneTimeEmailCampaignTemplateDesign
              field={field as unknown as Field<z.infer<typeof adminOneTimeEmailCampaignTemplateDesignForm>>}
              disabled={isViewMode || isUpdating}
              isDirty={isDirty}
              onEditorInit={onEditorInit}
            />
          </VStack>
        </AdminPageContent>
      </Form>

      <Surface destination={routes.admin.oneTimeEmailCampaignsTemplates.create.publishModal}>
        <Window>
          <AdminOneTimeEmailCampaignTemplatePublishModal
            closeHref={nav.closeSurfaceHref(routes.admin.oneTimeEmailCampaignsTemplates.create.publishModal)}
            onPublish={onPublish}
            isLoading={isUpdating}
            templateName={getValues('campaignName')}
          />
        </Window>
      </Surface>

      <Surface destination={routes.admin.oneTimeEmailCampaignsTemplates.sendTestEmailModal}>
        <Window>
          <SendTestEmailModal
            closeHref={nav.closeSurfaceHref(routes.admin.oneTimeEmailCampaignsTemplates.sendTestEmailModal)}
            signoff=""
            subjectLine={formatMessage(adminOneTimeEmailCampaignTemplatesMessages.adminSubjectLine)}
            senderAddress=""
            replyToAddress=""
            previewText=""
            template={generateEmailCampaignPreviewTemplate('', getValues().emailBodyParts ?? '', '', '', '')}
            emailBody={getValues().emailBodyParts ?? ''}
            emailGreeting=""
            footer=""
            signature=""
            campaignType={MarketingCampaignTypeEnum.ONE_TIME}
            withPreview={false}
            onValidate={() => trigger(['campaignName', 'emailBodyParts'], { shouldFocus: true })}
          />
        </Window>
      </Surface>

      {emailCampaignTemplate && (
        <>
          <Surface destination={routes.admin.oneTimeEmailCampaignsTemplates.edit.archiveModal}>
            <Window>
              <AdminOneTimeEmailCampaignTemplateArchiveModal
                closeHref={nav.closeSurfaceHref(routes.admin.oneTimeEmailCampaignsTemplates.edit.archiveModal, {
                  params: { templateId: emailCampaignTemplate.id },
                })}
                emailCampaignTemplate={emailCampaignTemplate}
                onArchive={onArchive}
                isLoading={isUpdating}
              />
            </Window>
          </Surface>

          <Surface destination={routes.admin.oneTimeEmailCampaignsTemplates.edit.unarchiveModal}>
            <Window>
              <AdminOneTimeEmailCampaignTemplateUnarchiveModal
                closeHref={nav.closeSurfaceHref(routes.admin.oneTimeEmailCampaignsTemplates.edit.unarchiveModal, {
                  params: { templateId: emailCampaignTemplate.id },
                })}
                emailCampaignTemplate={emailCampaignTemplate}
                onUnarchive={onUnarchive}
                isLoading={isUpdating}
              />
            </Window>
          </Surface>

          <Surface destination={routes.admin.oneTimeEmailCampaignsTemplates.edit.deactivateModal}>
            <Window>
              <AdminOneTimeEmailCampaignTemplateDeactivateModal
                closeHref={nav.closeSurfaceHref(routes.admin.oneTimeEmailCampaignsTemplates.edit.deactivateModal, {
                  params: { templateId: emailCampaignTemplate.id },
                })}
                emailCampaignTemplate={emailCampaignTemplate}
                onDeactivate={onDeactivate}
                isLoading={isUpdating}
              />
            </Window>
          </Surface>

          <Surface destination={routes.admin.oneTimeEmailCampaignsTemplates.edit.saveDeactivateModal}>
            <Window>
              <AdminOneTimeEmailCampaignTemplateSaveDeactivateModal
                closeHref={nav.closeSurfaceHref(routes.admin.oneTimeEmailCampaignsTemplates.edit.saveDeactivateModal, {
                  params: { templateId: emailCampaignTemplate.id },
                })}
                emailCampaignTemplate={emailCampaignTemplate}
                onSaveDeactivate={onSaveDeactivate}
                onDiscardDeactivate={onDiscardDeactivate}
                isLoading={isUpdating}
              />
            </Window>
          </Surface>

          <Surface destination={routes.admin.oneTimeEmailCampaignsTemplates.edit.duplicateModal}>
            <Window>
              <AdminOneTimeEmailCampaignTemplateDuplicateModal
                closeHref={nav.closeSurfaceHref(routes.admin.oneTimeEmailCampaignsTemplates.edit.duplicateModal, {
                  params: { templateId: emailCampaignTemplate.id },
                })}
                emailCampaignTemplate={emailCampaignTemplate}
                onSaveInAdmin={onDuplicateSaveInAdmin}
                onPublish={onDuplicatePublish}
                isLoading={isUpdating}
              />
            </Window>
          </Surface>

          <Surface destination={routes.admin.oneTimeEmailCampaignsTemplates.edit.unsavedModal}>
            <Window>
              <UnsavedChangesModal
                onCancel={() => {
                  nav.closeSurface(
                    nav.closeSurfaceHref(routes.admin.oneTimeEmailCampaignsTemplates.edit.unsavedModal, {
                      params: { templateId: emailCampaignTemplate.id },
                    })
                  )
                }}
                onDiscard={() => {
                  nav.push(routes.admin.oneTimeEmailCampaignsTemplates, {
                    queryMode: 'clear',
                  })
                }}
              />
            </Window>
          </Surface>
        </>
      )}
    </>
  )
}
