import FroalaReactEditorView from 'react-froala-wysiwyg/FroalaEditorView'
import type { OneTimeEmailCampaignTemplate } from '@sevenrooms/core/api'
import { OneTimeEmailCampaignStatusEnum } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BorderedBox, Box, DividerLine, HStack, VStack, ScalableBox } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import { useOneTimeEmailCampaignTemplateCategories, generateEmailCampaignPreviewTemplate } from '@sevenrooms/marketing'
import { useEmailCampaignTemplateStatusLabel } from '../../hooks'
import { adminOneTimeEmailCampaignTemplatesMessages } from '../../locales'

export interface AdminOneTimeEmailCampaignTemplateCardProps {
  emailTemplate: OneTimeEmailCampaignTemplate
}

export function AdminOneTimeEmailCampaignTemplateCard({ emailTemplate }: AdminOneTimeEmailCampaignTemplateCardProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { id: templateId } = emailTemplate
  const categories = useOneTimeEmailCampaignTemplateCategories()
  const isArchived = emailTemplate.status === OneTimeEmailCampaignStatusEnum.INACTIVE
  const statusLabel = useEmailCampaignTemplateStatusLabel(emailTemplate)
  const templateString = generateEmailCampaignPreviewTemplate(
    emailTemplate.greeting ?? '',
    emailTemplate.emailBodyParts?.join('<br/>') ?? '',
    emailTemplate.signature ?? '',
    emailTemplate.signoff ?? '',
    emailTemplate.footer ?? ''
  )

  return (
    <BorderedBox data-test="template-card" p="lm" width="100%" height="100%" boxSizing="border-box" backgroundColor="primaryBackground">
      <HStack spacing="lm" width="100%" height="100%">
        <Box overflow="hidden" width="172px" height="269px">
          <ScalableBox scaleWidth>
            <FroalaReactEditorView model={templateString} />
          </ScalableBox>
        </Box>

        <VStack spacing="m">
          {statusLabel && (
            <Box>
              <StatusLabel variant={statusLabel.variant}>{statusLabel?.text}</StatusLabel>
            </Box>
          )}

          <Box height="54px" maxHeight="54px" lineClamp={3}>
            <Text fontWeight="bold" data-test="template-name">
              {emailTemplate.unpublishedChanges?.campaignName || emailTemplate.campaignName}
            </Text>
          </Box>

          <DividerLine margin="none" />

          <VStack spacing="lm">
            <Box height="36px" maxHeight="36px" lineClamp={2}>
              <SecondaryText>
                {emailTemplate.templateCategories
                  ?.map(templateCategory => categories.find(category => category?.id === templateCategory)?.label)
                  .join(', ')}
              </SecondaryText>
            </Box>

            <Box>
              <Button
                variant="secondary"
                size="s"
                href={nav.href(routes.admin.oneTimeEmailCampaignsTemplates.edit, { params: { templateId } })}
                data-test="one-time-email-campaign-template-card-edit-button"
              >
                {isArchived
                  ? formatMessage(adminOneTimeEmailCampaignTemplatesMessages.view)
                  : formatMessage(adminOneTimeEmailCampaignTemplatesMessages.edit)}
              </Button>
            </Box>

            <Box>
              <SecondaryText fontSize="s">
                {formatMessage(adminOneTimeEmailCampaignTemplatesMessages.lastEdited)}&nbsp;
                {emailTemplate.updated?.formatNYearNMonthNDaySTime()}
              </SecondaryText>
            </Box>
          </VStack>
        </VStack>
      </HStack>
    </BorderedBox>
  )
}
