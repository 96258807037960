import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/utils'
import { AdminSyncBlobs } from './components'

function renderAdminSyncBlobs(elementId: string, venueId: string, venueName: string, modelNamesString: string) {
  return render(
    <AdminPage>
      <AdminSyncBlobs venueId={venueId} venueName={venueName} modelNames={JSON.parse(modelNamesString.replaceAll('&quot;', '"'))} />
    </AdminPage>,
    document.getElementById(elementId)
  )
}

svrExport('AdminSyncBlobs', 'render', renderAdminSyncBlobs)
