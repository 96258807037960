import { useMemo } from 'react'
import { useForm } from '@sevenrooms/core/form'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { getDefaultSyncBlobForm, useSyncBlobForm } from './AdminSyncBlob.zod'
import { AdminSyncBlobViewer } from './AdminSyncBlobViewer'

interface AdminSyncBlobsProps {
  venueId: string
  venueName: string
  modelNames: string[]
}

export function AdminSyncBlobs({ venueId, venueName, modelNames: validModelNames }: AdminSyncBlobsProps) {
  const nav = useNavigation()
  const [dateStart, modelNamesStart, useFancyDisplayStart, showDeletedModelsStart, searchStart] = useMemo(() => {
    const {
      date: dateStart,
      modelNames: modelNamesStart,
      useFancyDisplay: useFancyDisplayStart,
      showDeletedModels: showDeletedModelsStart,
      search: searchStart,
    } = {
      ...{
        date: new Date(),
        modelNames: 'Actual',
        useFancyDisplay: false,
        showDeletedModels: true,
        search: '',
      },
      ...nav.matchQuery(routes.admin.syncBlobs),
    }
    return [dateStart, modelNamesStart, useFancyDisplayStart, showDeletedModelsStart, searchStart]
  }, [nav])

  const schema = useSyncBlobForm()
  const { field } = useForm(schema, {
    defaultValues: getDefaultSyncBlobForm({
      dateStart,
      modelNamesStart,
      useFancyDisplayStart,
      showDeletedModelsStart,
      searchStart,
    }),
  })

  return <AdminSyncBlobViewer venueId={venueId} venueName={venueName} validModelNames={validModelNames} field={field} />
}
