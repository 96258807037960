import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/utils'
import { AdminAutoTags } from './components'

function renderAdminAutoTags(id: string) {
  return render(
    <AdminPage>
      <AdminAutoTags />
    </AdminPage>,
    document.getElementById(id)
  )
}

svrExport('AdminAutoTags', 'render', renderAdminAutoTags)
