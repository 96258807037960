import { useCallback, useEffect } from 'react'
import { type AdminSyncBlobs, type AdminSyncBlobsCounts, useGetAdminSyncBlobsMutation } from '@sevenrooms/core/api'
import { useWatchMany, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Checkbox, FormInput, FormMultiSelect, LoaderButton, SingleDatePicker, Label } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { adminSyncBlobsMessages } from '../../locales'
import { useSyncModelOptions, type SyncBlobForm } from './AdminSyncBlob.zod'

interface AdminSyncBlobOptionsProps {
  venueId: string
  isLoading: boolean
  field: Field<SyncBlobForm>
  validModelNames: string[]
  setIsLoading: (value: boolean) => void
  setSyncBlobs: (value: AdminSyncBlobs) => void
  setCounts: (value: AdminSyncBlobsCounts) => void
  setDeletedCounts: (value: AdminSyncBlobsCounts) => void
}

export function AdminSyncBlobOptions({
  venueId,
  isLoading,
  field,
  validModelNames,
  setIsLoading,
  setSyncBlobs,
  setCounts,
  setDeletedCounts,
}: AdminSyncBlobOptionsProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const modelNameOptions = useSyncModelOptions(validModelNames)

  const [date, modelNames, useFancyDisplay, showDeletedModels, search] = useWatchMany(field, [
    'date',
    'modelNames',
    'useFancyDisplay',
    'showDeletedModels',
    'search',
  ])

  const [getAdminSyncBlobsMutation] = useGetAdminSyncBlobsMutation()
  const refreshSyncBlobs = useCallback(async () => {
    if (!date || modelNames.length === 0) {
      return
    }
    setIsLoading(true)
    const data = await getAdminSyncBlobsMutation({
      venueId,
      modelNames,
      date,
      showDeletedModels,
      search,
    })
    if ('data' in data) {
      setIsLoading(false)
      setSyncBlobs(data.data.result)
      setCounts(data.data.counts)
      setDeletedCounts(data.data.deleted_counts)

      nav.push(routes.admin.syncBlobs, {
        params: { venueId },
        query: {
          date: date || new Date(),
          modelNames: modelNames.join(','),
          useFancyDisplay,
          showDeletedModels,
          search,
        },
      })
    }
  }, [
    date,
    getAdminSyncBlobsMutation,
    modelNames,
    nav,
    search,
    setCounts,
    setDeletedCounts,
    setIsLoading,
    setSyncBlobs,
    showDeletedModels,
    useFancyDisplay,
    venueId,
  ])

  useEffect(() => {
    refreshSyncBlobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <VStack spacing="s">
      <HStack spacing="s">
        <Label
          primary={formatMessage(adminSyncBlobsMessages.date)}
          info={<>{formatMessage(adminSyncBlobsMessages.dateTooltip)}</>}
          width="20%"
        >
          <SingleDatePicker
            field={field.prop('date')}
            isOutsideRange={() => false}
            placeholder={formatMessage(adminSyncBlobsMessages.datePlaceholder)}
          />
        </Label>
        <Label primary={formatMessage(adminSyncBlobsMessages.model)} width="35%">
          <FormMultiSelect
            options={modelNameOptions}
            field={field.prop('modelNames')}
            searchable
            placeholder={formatMessage(adminSyncBlobsMessages.modelPlaceholder)}
          />
        </Label>
        <Label
          primary={formatMessage(adminSyncBlobsMessages.search)}
          width="30%"
          info={<>{formatMessage(adminSyncBlobsMessages.searchTooltip)}</>}
        >
          <FormInput field={field.prop('search')} placeholder={formatMessage(adminSyncBlobsMessages.searchPlaceholder)} />
        </Label>
        <Label primary="&nbsp;" width="15%">
          <LoaderButton data-test="fetch-blobs-button" onClick={() => refreshSyncBlobs()} loading={isLoading}>
            {formatMessage(adminSyncBlobsMessages.fetchBlobs)}
          </LoaderButton>
        </Label>
      </HStack>
      <HStack spacing="l">
        <Checkbox
          field={field.prop('showDeletedModels')}
          info={<Text color="lightFont">{formatMessage(adminSyncBlobsMessages.showDeletedModelsTooltip)}</Text>}
        >
          {formatMessage(adminSyncBlobsMessages.showDeletedModels)}
        </Checkbox>
      </HStack>
    </VStack>
  )
}
